.swiper-wrapper {
  padding-bottom: 40px;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

@media screen and (min-width: 768px) {
  .swiper-wrapper {
    padding-bottom: 0px;
  }
  .swiper-pagination,
  .swiper-pagination-clickable,
  .swiper-pagination-bullets,
  .swiper-pagination-horizontal {
    display: none;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
}

.swiper-pagination-bullet-active {
  background: #26d07c !important;
}

.swiper-pagination-bullet {
  background: transparent;
  border-width: 1px;
  width: 10px;
  height: 10px;
  border-color: #26d07c;
  opacity: 100%;
}

.swiper-wrapper {
  padding-bottom: 40px;
}
